import { objectNotEmpty, notAllObjectValuesAreEmpty } from "@/utils/basics";
import { localTimeToUtc } from "@/utils/dates";
import { transformAttachments, transformAttachmentsNew, transformFiles, transformLinks, transformMultilanguageFiles, transformMultilanguageObject, transformSimpleFiles, transformTags } from "./functions";
import { dateToISOString } from "@/utils/dates";

export function transformDataToUpload (state) {
  const {
		epId,
		mediaType,
		description,
		title,
		category,
		tags,
		seoTitle,
		seoDescription,
		seoKeywords,
		eventDate,
		endPublicationDate,
		startEmbargoDate,
		endEmbargoDate,
		startPublicationDate,
		termsOfUse,
		copyrightLicense,
		copyrightAuthor,
		copyrightYear,
		links,
		attachments,
		owner,
		files,
        multilanguageFiles,
		transcript
	} = state;

	return {
		definitions: {
			mediaType,
			category,
			epId: epId.data,
		},
		title,
		... (objectNotEmpty(description) && {description}),
		dates: {
			eventDate,
			modifiedDate: localTimeToUtc(new Date().getTime()),
			startPublicationDate,
			endPublicationDate,
			startEmbargoDate,
			endEmbargoDate
		},
		seo: {
			... (objectNotEmpty(seoTitle) && notAllObjectValuesAreEmpty(seoTitle) && {seoTitle}),
			... (objectNotEmpty(seoDescription) && notAllObjectValuesAreEmpty(seoDescription) && {seoDescription}),
			... (objectNotEmpty(seoKeywords) && notAllObjectValuesAreEmpty(seoKeywords) && {seoKeywords}),
		},
		legalNotice: {
			... (objectNotEmpty(termsOfUse) && notAllObjectValuesAreEmpty(seoDescription) && {termsOfUse}),
			... (objectNotEmpty(copyrightAuthor) && notAllObjectValuesAreEmpty(seoDescription) && {copyrightAuthor}),
			... (objectNotEmpty(copyrightLicense) && notAllObjectValuesAreEmpty(seoDescription) && {copyrightLicense}),
			copyrightYear
		},
		tags: transformTags(tags),
		attachments: transformAttachments({links, attachments}),
		owner,
		... (files.length > 0 && mediaType ==='EPV_AUDIO' && {mediaFiles: transformFiles(files)}),
		... (objectNotEmpty(transcript) && notAllObjectValuesAreEmpty(seoDescription) && {transcript}),
		... (files.length > 0 &&  mediaType ==='EPV_AUDIO_PODCASTS' && {thumbnail: transformSimpleFiles(files)}),
        ... (mediaType ==='EPV_INFOGRAPHICS' && {infographicFiles: transformMultilanguageFiles(multilanguageFiles)}),
	};
}

export function transformTopicToUpload (state) {
	const {
		title,
		shortDescription,
		description,
		tags,
		seoTitle,
		seoDescription,
		endPublicationDate,
		startPublicationDate,
		links,
		attachments,
		coverImage,
		files,
		editDate,
	} = state;

	return {
		attachmentMetadatas: transformAttachmentsNew(attachments),
		coverImage: coverImage?.url || '',
		descriptions: description ? transformMultilanguageObject(description) : [],
		shortDescriptions: shortDescription ? transformMultilanguageObject(shortDescription) : [],
		endDate: endPublicationDate ? dateToISOString(endPublicationDate) : '',
		linkMetadatas: transformLinks(links),
		originalImage: files[0]?.url || '',
		seoDescriptions: seoDescription ? transformMultilanguageObject(seoDescription) : [],
		seoTitles: seoTitle ? transformMultilanguageObject(seoTitle) : [],
		startDate: startPublicationDate ? dateToISOString(startPublicationDate) : '',
		tags: transformTags([...tags]),
		titles: title ? transformMultilanguageObject(title) : [],
		updateDate: dateToISOString(editDate),
	};
}