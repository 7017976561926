import { useEffect, useMemo } from 'react';

import { Input, Pagination, Radio, Row, Select, Space } from 'antd';
const { Search } = Input;

import AssociatedTable from '@Components/Tables/AssociatedTable';

import useNotification from '@/hooks/notifications';
import useFetchData from '@/hooks/useFetchData';

const types = [
	{ label: 'AUDIOS', value: 'audios' },
	{ label: 'VIDEOS', value: 'videos' },
	{ label: 'PHOTOS', value: 'photos' },
	{ label: 'PHOTOSETS', value: 'photosets' },
	{ label: 'INFOGRAPHICS', value: 'infographics' },
	{ label: 'STREAMS', value: 'streamings' },
];

const searchTypes = [
	{ label: 'Search by reference ID', value: 'epId' },
	{ label: 'Search by keywords', value: 'search' },
];

const mediaTypes = {
	'audios': 'EPV_AUDIO',
	'videos': 'EPV_VIDEO_FOOTAGE%3BEPV_EDITED_VIDEOS',
	'photos': 'EPV_PHOTO',
	'photosets': 'EPV_PHOTOSET',
	'podcasts' : 'EPV_AUDIO_PODCASTS',
	'infographics': 'EPV_INFOGRAPHICS',
	'streamings': 'WS_VIDEO'
};

export default function AssociatedMediasTable({url, selected = false}) {
	const {openNotification} = useNotification();

	const params = {type: 'audios', sort: 'modified:desc'};
	const {setParam, setParams, searchParams, data, loading, pagination, error, deleteParam} = useFetchData({params});

	const type = useMemo(() => searchParams.get("type") ?? 'audios', [searchParams.get("type")]);

	const associatedMediasParams = useMemo(() => {
		return {
			url,
			category: mediaTypes[type]
		};
	}, [type]);
	const associatedMedias= useFetchData({params: associatedMediasParams, useDefaultParams: true});

	const associatedList = useMemo(() => {
		if (!associatedMedias?.data) {
			return [];
		}
		return associatedMedias?.data?.map( media => {
			return {...media, selected: true};
		});

	}, [associatedMedias.data]);

	useEffect(() => {
		setParams({sort: 'mediaDate:desc'});
	}, []);

	[error, associatedMedias.error].forEach((err) => {
		useEffect(() => {
			if (err) {
				openNotification({message: 'Something went wrong!', description: err.message || null, type: 'error'});
			}
		}, [err]);
	});


	const displayData = useMemo(() => {
		if (selected) {
			return associatedList;
		}

		return data?.map( media => {
			const index = associatedList?.findIndex( a => {
				return a.mediaId === media.mediaId;
			});

			return {...media, selected: index !== -1};
		});
	}, [selected, data, associatedList]);

	const handleType = ({ target: { value } }) => {
		deleteParam('page', false);
		setParams({type: value, category: mediaTypes[value]});
	};

	const handlePagination = (page, pageSize) => {
		setParams({page, pageSize});
	};

	const handleSearch = () => {
		setParams({sort: 'relevancy:asc'});
	};

	const getListData = async () => {
    associatedMedias.getData();
	};

	return (
		<Space size="large" direction='vertical' style={{width: '100%'}} >
			<Radio.Group
				options={types}
				onChange={handleType}
				value={type}
				optionType="button"
				size="large"
			/>
			{!selected && <Search
				addonBefore={
					<Select
						style={{width: 190}}
						options={searchTypes}
						value={searchParams.get("searchType") || 'search'}
						onChange={setParam('searchType')}
					/>
				}
				value={searchParams.get("search") || ''}
				onChange={setParam('search', false)}
				onSearch={handleSearch}
				enterButton
			/>}
			<>
				<AssociatedTable
					data={displayData}
					loading={loading}
					sort={searchParams.get("sort") || 'mediaDate:desc'}
					setSort={setParam('sort')}
					getListData={getListData}
					type={type}
					name="medias"
					selectItem={async (id) => await associatedMedias.toggleSelect({id, select: true})}
					unselectItem={async (id) => await associatedMedias.toggleSelect({id, select: false})}
					dispatched={false}
				/>
				{!selected && <Row justify="center">
					<Pagination
						total={pagination.totalElements}
						pageSize={pagination.pageSize}
						current={pagination.page}
						showSizeChanger
						showQuickJumper
						hideOnSinglePage
						disabled={loading}
						onChange={handlePagination}
					/>
				</Row>}
			</>
		</Space>
	);
}